.type-PO {
  border: 3px solid black
}

.edgePath path.path {
  stroke: transparent;
  fill: transparent;
  stroke-width: 1.3px;
}

.pencil-button {
  cursor: pointer;
}